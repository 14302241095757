.App-body-right-tab-agent-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.App-body-right-tab-agent-title {
  height: 7%;
  display: flex;
  flex-direction: row;
  border-bottom : solid #D4D5D6 2px;
}

.App-body-right-tab-agent-title-container {
  margin : 1% 1%;
  color: #161616;
  display: flex;
  width: 100%;
  flex-direction: row
}

.App-body-right-tab-agent-body {
  height: 93%;
  background-color: #F4F5F6;
  display: flex;
  flex-direction: column;
}

.App-body-right-tab-agent-title-name {
  font-family: "Proxima Nova Semibold";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 29px;
}

.App-body-right-tab-dashboard-refresh {
  margin: auto;
  margin-right: 10px;
  border : 1px solid black;
  height: 100%;
  width: 2%;
  background-color: #F4F5F6;
}

#App-body-right-tab-dashboard-refresh-icon{
  margin: 6px;
  cursor: pointer;
}

#App-body-right-tab-dashboard-spinner-icon{
  margin: 6px;
}