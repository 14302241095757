.App-body-right-tab-agent-content-table-container {
  height : 98%;
  margin : 10px;
  overflow: scroll;
}

.App-body-right-tab-agent-content-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.App-body-right-tab-agent-content-table td {
  padding: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 17px;
  height : 25px;
}

.App-body-right-tab-agent-content-table tr {
  border-bottom: 1px solid #EDEDED;
}

.App-body-right-tab-agent-content-table th {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height : 35px;
  top : 0;
  position: sticky;
}

#App-body-right-tab-agent-content-table-data-dealer-id-header {
  text-align: center;
  width : 5%;
}

#App-body-right-tab-agent-content-table-data-dealer-id {
  text-align : right;
  padding-right: 20px;
}

#App-body-right-tab-agent-content-table-data-agent-name {
  width : 10%;
}

#App-body-right-tab-agent-content-table-data-agent-display-name {
  width : 20%;
}

#App-body-right-tab-agent-content-table-data-agent-tenant-name {
  width : 15%;
}

#App-body-right-tab-agent-content-table-data-agent-dealership-code {
  width : 15%;
}

#App-body-right-tab-agent-content-table-data-agent-type {
  width : 5%;
}

#App-body-right-tab-agent-content-table-data-agent-status {
  width : 10%;
}