.App-body-right-tab-status-accordion-details-module-data-container {
	width: 85%;
	border : 1px solid #D4D5D6;
	display: flex;
	flex-direction: column;
}

.App-body-right-tab-status-accordion-details-module-data-title-container {
	height: 8%;
	margin : 10px;
}

.App-body-right-tab-status-accordion-details-module-data-content-container {
	height: 90%;
}

.App-body-right-tab-status-accordion-details-module-data-add-year {
	width : 10%;
	height: 100%;
	float : left;
	border: 1px solid #969AA3;
  	border-radius: 2px;
	display: flex;
}

.App-body-right-tab-status-accordion-details-module-data-sub-module-container {
	width: 25%;
	float : right;
	height : 100%;
	display: flex;
	flex-direction: row;
}

.App-body-right-tab-status-accordion-details-module-data-add-year-button {
	color: #161616;
	font-family: "Proxima Nova";
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 17px;
	text-align: center;
	margin: auto;
	width : 100%;
	cursor: pointer;
}

.App-body-right-tab-status-accordion-details-module-data-sub-module-filter-label {
	margin: auto;
	color: #444F5C;
 	font-family: "Proxima Nova";
 	font-size: 14px;
 	letter-spacing: 0.3px;
 	line-height: 17px;
 	text-align: right;
}

.App-body-right-tab-status-accordion-details-module-data-sub-module-filter {
	margin: auto;
	width : 70%;
	height: 100%;
	box-sizing: border-box;
	border: 1px solid #969AA3;
  	border-radius: 2px;
  	color: #161616;
 	font-family: "Proxima Nova";
 	font-size: 14px;
 	letter-spacing: 0.3px;
 	line-height: 17px;
}