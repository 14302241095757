.App-body-count-validation-modal {
	display: flex; 
    position: fixed; 
    z-index: 1;
    margin: auto; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.App-body-count-validation-modal-content {
    position: relative;
    background-color: #ffffff ;
    margin: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #888;
    width: 35%;
    height: 35%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
     animation-duration: 0.4s
}

.App-body-size-validation-modal-content {
    position: relative;
    background-color: #ffffff ;
    margin: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #888;
    width: 35%;
    height: 50%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
     animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.close {
    color: black;
    float: right;
    margin : auto;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.App-body-count-validation-modal-header {
    background-color: #EDEEF0;
    height: 20%;
    display: flex;
    flex-direction: row;
}

.App-body-count-validation-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}

.App-body-count-validation-modal-body {
  height : 80%;
  overflow: scroll;
}

.App-body-count-validation-modal-body-container {
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.App-body-count-validation-modal-body-table-header  {
    display: flex;
    height: 20%;
}

.App-body-count-validation-modal-body-table-body {
    display: flex;
    height: 50%;
}

.App-body-count-validation-modal-body-log-table-title-content {
    border-collapse: collapse;
    padding: 0.5rem;
    text-align: left;
    background-color: #F4F4F4;
    border-right: 1px solid #DEDFE0;
    color: #161616;
    font-family: "Proxima Nova Semibold";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 17px;
    height: 35px;
    top: 0;
    position: sticky;
    display: flex;
    flex-direction: row;
}

.App-body-count-validation-modal-body-log-table-content {
    padding: 0.5rem;
    border-collapse: collapse;
    text-align: left;
    color: #161616;
    border-right: 1px solid #DEDFE0;
    font-family: "Proxima Nova";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 17px;
    height : 30px;
    display: flex;
    border-bottom : 1px solid #D4D5D6;
}

.App-body-count-validation-modal-body-log-table-title-content-detail {
    margin: auto 10px;
}

#App-body-count-validation-modal-title-module {
    width: 20%;
    border-left: 1px solid #DEDFE0;
}

#App-body-count-validation-modal-title-actual-count{
    width: 20%;
}

#App-body-count-validation-modal-title-available-count {
    width: 20%;
}

#App-body-count-validation-modal-title-metadata-count {
    width: 20%;
}

#App-body-count-validation-modal-title-validated {
    width: 20%;
}

.size-validation-message{
    font-size: 21px;
}

.size_validation_table{
  margin: 20px;
  width: auto;
  height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #D4D5D6;
}

.size_validation_table_header {
    height: 20%;
    display: flex;
    border-collapse: collapse;
    padding: 0.5rem;
    text-align: left;
    background-color: #F4F4F4;
    border-right: 1px solid #DEDFE0;
    color: #161616;
    font-family: "Proxima Nova Semibold";
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 17px;
    height: 35px;
    top: 0;
    position: sticky;
    display: flex;
    flex-direction: row;
}

.size_validation_table_body {
    display: flex;
    flex-direction: column;
}

.size_validation_table_row {
    padding: 0.5rem;
    border-collapse: collapse;
    text-align: left;
    color: #161616;
    border-right: 1px solid #DEDFE0;
    font-family: "Proxima Nova";
    font-size: 15px;
    letter-spacing: 0.3px;
    line-height: 17px;
    height : 30px;
    display: flex;
    border-bottom : 1px solid #D4D5D6;
    width: 100%;
}