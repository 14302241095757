.module-progress-bar-container {
	display: flex;
	flex-direction: column;
	margin: 2px;
}

.module-progress-bar-envelope {
	width : 100%;
	background-color : #ece7e7;
	height : 5px;
	margin-top : 10px;
	margin-right : 10px;
}

.module-progress-bar-details {
	display: flex;
	flex-direction: row;
}

.module-progress-bar-percentage {
	float : right;
	width : 20%;
	color: #444F5C;
    font-family: "Proxima Nova";
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: center
}