.App-body-right-tab-status-accordion-details {
	display: flex;
	flex-direction: row;
	height : 500px;
	overflow: scroll;
	width: 100%;
}

.App-body-right-tab-status-accordion-details-left-space {
	width : 3%;
}