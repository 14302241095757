.App-body-right-tab-status-table-status-label-container {
	display: flex;
	flex-direction: row;
}

.App-body-right-tab-status-lable-icon {
	height: 12px;
  	width: 12px;
  	border-radius: 12px;
  	margin-top : 3px;
}

.extracted-color {
	background-color: #60D156;
}

.fail-color {
	background-color: #F52908;
}

.run-color {
	background-color: #99C2FF;
}

.cancel-color {
	background-color: #FF8800;;
}

.validation-color {
	background-color: #768F6D;
}

.pending-color {
	background-color: #FF8800;
}