.App-body-migrate-modal {
  display: flex; 
  position: fixed; 
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
}

/* Modal Content */
.App-body-migrate-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 20%;
  height: 20%;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.App-body-migrate-modal-header {
  height: 25%;
  background-color: #EDEEF0;
  display: flex;
  flex-direction: row
}

.App-body-migrate-modal-body {
  height: 40%;
  text-align: left;
  display: flex;
}

.App-body-migrate-modal-footer {
  background-color: #EDEEF0;
  height: 35%;
  display: block;
  flex-direction: row;
}

.App-body-migrate-modal-body-label {
  margin: auto;
  display: flex;
  width: 80%;
  height: 70%;
  color : red;
  text-align: left;
  font-family: "Proxima Nova";
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 25px;
}

.App-body-migrate-modal-footer-cancel {
  border : 1px solid black;
  float : right;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  margin: 20px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width : 20%
}

.App-body-migrate-modal-footer-proceed {
  float : right;
  border : 1px solid black;
  border-radius: 2px;
  background-color: #4285F4;
  display: flex;
  color: #FFFFFF;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
  height: 50%;
  width : 20%
}

.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-migrate-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}