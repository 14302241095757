.App-body-right-tab-dashboard-content-container {
  display: flex;
  flex-direction: column;
  height: 73%;
  margin: 0px 15px 10px 15px;
}

.App-body-right-tab-dashboard-content-title-container {
  height: 5%;
}

.App-body-right-tab-dashboard-content-title {
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 29px;
  float : left;
  margin-left : 5px;
}

.App-body-right-tab-dashboard-content-contents {
  height: 95%;
  background-color: white;
  margin : 10px 5px;
  border-radius: 6px;
}