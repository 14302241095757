.App-body-error-modal {
  display: flex; 
  position: fixed; 
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto;
}

/* Modal Content */
.App-body-error-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 20%;
  height: 20%;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.App-body-error-modal-header {
  height: 25%;
  background-color: #EDEEF0;
  display: flex;
  flex-direction: row
}

.App-body-error-modal-body {
  height: 80%;
  text-align: left;
  display: flex;
}

.App-body-error-modal-body-label {
  margin: auto;
  display: flex;
  width: 80%;
  height: 70%;
  color : red;
  text-align: left;
  font-family: "Proxima Nova";
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 25px;
}

.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-error-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}