.App-body-status-year-add-job-modal {
  display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.App-body-status-year-add-job-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 35%;
  height: 35%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-status-year-add-job-modal-header {
  background-color: #EDEEF0;
  height: 15%;
  display: flex;
  flex-direction: row;
}

.App-body-status-year-add-job-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}

.App-body-status-year-add-job-modal-body {
  height : 70%;
  display : flex;
  flex-direction: column;
  border-bottom: 1px solid #D4D5D6;
}

.App-body-status-year-add-job-modal-body-label {
  margin: 40px 30px 30px 30px;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 17px;
  height: 40%;
  width: 80%;
  text-align: left;

}

.App-body-status-year-add-job-modal-body-year-dropdown-container {
  height: 40%;
  width: 80%;
  margin: 0px 30px 40px 30px;
  display: flex;
  flex-direction: column;
}

.App-body-status-year-add-job-modal-body-year-dropdown-label {
  color: #444F5C;
  font-family: "Proxima Nova";
  font-size: 20px;
  letter-spacing: 0.3px;
  line-height: 17px;
  text-align: left;
  height: 30%;
  margin-bottom: 20px ;
}

.App-body-status-year-add-job-modal-body-year-dropdown {
  width : 70%;
  height: 60%;
  box-sizing: border-box;
  border: 1px solid #969AA3;
  border-radius: 2px;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-status-year-add-job-modal-footer {
  background-color: white;
  height: 20%;
  display: block;
  flex-direction: row;
}

.App-body-status-year-add-job-modal-footer-cancel {
  border : 1px solid black;
  float : right;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  margin: 20px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width : 20%
}

.App-body-status-year-add-job-modal-footer-proceed {
  float : right;
  border : 1px solid black;
  border-radius: 2px;
  background-color: #4285F4;
  display: flex;
  color: #FFFFFF;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  margin: 20px;
  cursor: pointer;
  height: 50%;
  width : 20%
}