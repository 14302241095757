.App-body-right-tab-job-details-modal {
	display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.App-body-right-tab-job-details-modal-content {
	position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 70%;
  height: 70%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-right-tab-job-details-modal-header {
  background-color: #EDEEF0;
  height: 10%;
  display: flex;
  flex-direction: row;
}

.job_details_header {
	margin-right: 20px;
}

.job_id_subscript{
  color: #a9a9a9;
  font-style: italic; 
  float: right; 
  font-size: 15px;
}

.App-body-right-tab-job-details-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-job-details-modal-body {
  height : 80%;
  overflow: scroll;
}

.App-body-right-tab-job-details-modal-body-details-container {
	display: flex;
	flex-direction: row;
	height: 30%;
	margin: 10px 20px;
}

.App-body-right-tab-job-details-modal-body-log-outer-container {
	display: flex;
	flex-direction: column;
	height: 50%;
	margin: 10px 20px;
}

.App-body-right-tab-job-details-modal-body-validation-result-container  {
	display: flex;
	flex-direction: column;
	height: 30% ;
	margin: 10px 20px;
}

.App-body-right-tab-job-details-modal-body-log-header {
	height: 10%;
	display: flex;
}

.App-body-right-tab-job-details-modal-body-log-header-content {
	margin: 5px 25px;
	color: #161616;
	font-family: "Proxima Nova";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 22px
}

.App-body-right-tab-job-details-modal-body-log-content {
	height: 90%;
	display: flex;
}

.App-body-right-tab-job-details-modal-body-details-1 {
	display: flex;
	flex-direction: column;
	width: 30%;
}

.App-body-right-tab-job-details-modal-body-details-2 {
	display: flex;
	flex-direction: column;
	width: 20%;
}

.App-body-right-tab-job-details-modal-body-details-3 {
	display: flex;
	flex-direction: column;
	width: 25%;
}

.App-body-right-tab-job-details-modal-body-details-4 {
	display: flex;
	flex-direction: column;
	width: 25%;
}

.App-body-right-tab-job-details-modal-body-detail-container {
	height: 50%;
	display: flex;
	width: 90%;
	margin: 10px;
	flex-direction: column;
}

.App-body-right-tab-job-details-modal-body-detail-header {
	width: 90%;
	display: flex;
	height: 50%;
	margin: auto;
	color: #444F5C;
 	font-family: "Proxima Nova";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-right-tab-job-details-modal-body-detail-content {
	width: 90%;
	display: flex;
	height: 50%;
	margin: 10px auto;
	color: #161616;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-right-tab-job-details-modal-footer {
	height: 10%;
	width: 90%;
	margin: 10px auto;
}

.App-body-right-tab-job-details-modal-close {
	width: 10%;
	height: 60%;
	border-radius: 2px;
  background-color: #4285F4;
	float : right;
	margin: 10px 0px;
	color: #FFFFFF;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  display: flex;
  text-align: center;
  cursor: pointer;
}

.App-body-right-tab-job-details-modal-close-label {
	margin: auto;
}

#App-body-right-tab-job-details-modal-body-detail-user-profile{
	margin: 0 10px;
}





