.App-body-right-tab-status-body-contents {
  height: 100%;
  margin: 0px 10px;
  overflow: scroll;
}

.App-body-right-tab-status-table-status-content {
  padding: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 17px;
  height : 30px;
  display: flex;
}

.App-body-right-tab-status-table-status-content-detail {
  margin: auto 10px;
}

#App-body-right-tab-status-table-collapse-icon{
  width: 1%;
}

#App-body-right-tab-status-table-group-name{
  width: 20%;
}

#App-body-right-tab-status-table-dealership-name{
  width: 25%; 
}

#App-body-right-tab-status-table-dealer-id{
  width: 8%;
  display: flex;
}

#App-body-right-tab-status-table-dms-type{
  width: 8%;
}

#App-body-right-tab-status-table-dealer-id-content {
  text-align: right;
  width: 60%;
}

#App-body-right-tab-status-table-go-live-date{
  width: 10%;
}

#App-body-right-tab-status-table-overall-completion{
  width: 11%;
}

#App-body-right-tab-status-table-last-updated{
  width: 12%;
}

#App-body-right-tab-status-table-status{
  width: 12%;
}

#App-body-right-tab-status-table-overflow {
  width: 4%;
  border-left: 1px solid #DEDFE0;
}

#App-body-right-tab-status-table-status-contents {
  flex-direction: row;
  display: flex;
}

#App-body-right-tab-status-table-status-contents-info {
  margin: 0 10px;
  cursor: pointer;
}

.accordion {
  width: 600px;
  margin: 2rem auto;
}

.App-body-right-tab-status-table-status-accordion-item {
   border-bottom: 1px solid #EDEDED;
}

.App-body-right-tab-status-table-status-accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}


.App-body-right-tab-status-table-status-accordion-title,
.App-body-right-tab-status-table-status-accordion-content {
  padding: 1rem auto;
}

.App-body-right-tab-status-table-status-accordion-content {
  background-color: white;
}

#App-body-right-tab-status-table-add-info {
  position: relative;
  display: inline-block;
}

#App-body-right-tab-status-table-status-add-info {
  position: relative;
  margin: auto;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}