.App-body-right-tab-dashboard-overview-agent-online {
  background-color: blue;
  margin : 10px;
  width : 20%;
  height: 80%;
  box-sizing: border-box;
  border: 1px solid #EDEDED;
  border-radius: 6px;
  background-color: #FFFFFF;
  display : flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-overview-agent-online-icon {
  margin: 20px 10px;
  background-color: #e1f7ee;
  border-radius: 50%;
  height: 54px;
  width: 54px;
  display: flex;
}

.App-body-right-tab-dashboard-overview-agent-online-icon-container {
  margin : auto;
  height: 24px;
  width: 24px;
}

.App-body-right-tab-dashboard-overview-agent-online-content {
  margin: 5px;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
}

.App-body-right-tab-dashboard-overview-agent-online-content-title {
  background-color: white;
  margin-top: 25px;
  margin-left: 15px;
  color: #444F5C;
  font-family: "Proxima Nova";
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 17px;
  text-align: left;
}

.App-body-right-tab-dashboard-overview-agent-online-content-title a {
  text-decoration: none;
  color: #4285F4;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.App-body-right-tab-dashboard-overview-agent-online-content-count {
  margin-top: 5px;
  margin-left: 15px;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 17px;
  text-align: left;
}

.App-body-right-tab-dashboard-overview-agent-online-icon-logo path {
  fill : red;
}