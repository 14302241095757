.App-body-right-tab-status-year-progress-contents {
  height: 88%;
  margin: 0px 10px;
  overflow: auto;
  max-height: 100%;
}

.App-body-right-tab-status-year-progress-content {
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  height : 13%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-year-progress-body-content {
  margin: auto 10px;
}

#App-body-right-tab-year-progress-body-year {
  width: 10%;
}

#App-body-right-tab-year-progress-body-extract-description {
  width: 70%;
  display: flex;
  flex-direction: row;
}

#App-body-right-tab-year-progress-body-actions {
  width: 20%;
  display: flex;
}