.App-body-status-year-job-insights-modal-body-document-status {
  height: 55%;
  display: flex;
  flex-direction: column;
}

.App-body-status-year-job-insights-modal-body-document-status-title {
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  background-color: white;
  line-height: 22px;
  margin: 10px;
  margin-left: 40px;
  height: 10%;
  display: flex;
}

.App-body-status-year-job-insights-modal-body-document-status-table {
  display: flex;
  flex-direction: column;
  height: 80%;
  margin-left: 30px;
}

.App-body-status-year-job-insights-modal-body-document-status-table-title {
  display: flex;
  flex-direction: row;
  height: 20%;
  margin : 10px 10px 0px 10px;
}

.App-body-status-year-job-insights-modal-body-document-status-table-details {
  display: flex;
  flex-direction: column;
  height: 70%;
  margin : 0px 10px 0px 10px;
}

.App-body-status-year-job-insights-modal-body-document-status-table-details-row {
  display: flex;
  flex-direction: row;
}

.App-body-status-year-job-insights-modal-body-document-status-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height: 30px;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
}

.App-body-status-year-job-insights-modal-body-document-status-detail-content {
  font-family: "Proxima Nova";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  border-right: 1px solid #DEDFE0;
  height: 30px;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
  color: #161616;
}

.App-body-status-year-job-insights-modal-body-document-status-title-content-detail {
  margin: auto 10px;
}

#App-body-status-year-job-insights-modal-body-document-status-document-module {
  width: 20%;
}

#App-body-status-year-job-insights-modal-body-document-status-document-estimate {
  width: 20%;
}

#App-body-status-year-job-insights-modal-body-document-status-document-extract {
  width: 20%;
}

#App-body-status-year-job-insights-modal-body-document-status-document-progress {
  width: 30%;
}

#App-body-status-year-job-insights-modal-body-document-status-document-module-value {
  width: 20%;
  font-weight: 600;
}

#App-body-status-year-job-insights-modal-body-document-status-document-estimate-value {
  width: 20%;
  color: #39BF2D;
}

#App-body-status-year-job-insights-modal-body-document-status-document-extract-value {
  width: 20%;
  color: #F52908;
}

#App-body-status-year-job-insights-modal-body-document-status-document-progress-value {
  width: 30%;
}