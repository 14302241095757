.App-body-right-tab-status-body-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
}

.App-body-right-tab-status-body-title {
  display: flex;
  flex-direction: row;
  margin : 10px 10px 0px 10px;
}

.App-body-right-tab-status-body-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height: 35px;
  top: 0;
  position: sticky;
  display: flex;
}

.App-body-right-tab-status-body-title-content-detail {
  margin: auto 10px;
}

#App-body-right-tab-status-body-title-collapse-icon{
  width: 1%;
  border : none
}

#App-body-right-tab-status-body-title-group-name{
  width: 20%;
}

#App-body-right-tab-status-body-title-dealership-name{
  width: 25%; 
}

#App-body-right-tab-status-body-title-dealer-id{
  width: 8%;
}

#App-body-right-tab-status-body-title-dms-type{
  width: 8%;
}

#App-body-right-tab-status-body-title-go-live-date{
  width: 10%;
}

#App-body-right-tab-status-body-title-overall-completion{
  width: 11%;
}

#App-body-right-tab-status-body-title-last-updated{
  width: 12%;
}

#App-body-right-tab-status-body-title-status{
  width: 12%;
}

#App-body-right-tab-status-body-title-add-info{
  width: 4% ;
}
