.App-body-right-tab-job-details-modal-body-log-table-header {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #F4F5F6;
	height: 25%;
	margin-bottom: 10px;
}

.App-body-right-tab-job-details-modal-body-log-table-title-content {
	border-collapse: collapse;
  	padding: 0.5rem;
  	text-align: left;
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	font-family: "Proxima Nova Semibold";
  	font-size: 15px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height: 35px;
  	top: 0;
  	position: sticky;
  	display: flex;
  	flex-direction: row;
}

.App-body-right-tab-job-details-modal-body-log-table-title-content-detail {
	margin: auto 10px;
}

#App-body-right-tab-job-details-modal-title-audit-id {
	width: 30%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-title-status {
	width: 15%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-title-message {
	width: 35%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-title-triggered-time {
	width: 20%;
	margin: auto;
}