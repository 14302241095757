.App {
  text-align: center;
  height : 100vh;
  display: flex;
}

.App-body-container {
  display: flex;
  width: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family : "Proxima Nova";
  src : url("https://d36263b6wju30t.cloudfront.net/theme/v1.0.1/fonts/ProximaNova/proxima-nova-regular/proxima-nova-regular.woff2");
}

@font-face{
  font-family : "Proxima Nova Semibold";
  src : url("https://d36263b6wju30t.cloudfront.net/theme/v1.0.1/fonts/ProximaNova/proxima-nova-semibold/proxima-nova-semibold.woff2");
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-title-block {
  width : 100%;
  height : 7%;
  background-color : #3F4757;
  display: flex;
  position : fixed;
  flex-direction: row;
  border-bottom: solid #161616 1px;
}

.App-title-block-9-icon {
  height: 100%;
  width: 4%;
  color : white;
  justify-content: center;
  align-items: center;
  border-bottom: solid #161616 1px;
}

.App-title-block-logout {
  margin: auto;
  margin-left: 80%;
  display: flex;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.App-title-block-title {
  width: 8%;
  margin: 23px 15px;
}

.App-title-block-title-logo {
  max-height: 100%;
  max-width: 100%;
}

.App-body-block {
  background-color: cyan;
  display: block;
  width : 100%;
  height: 100%;
}

.App-body-block-upper-title-tab {
  width: 100%;
  height: 7%;
}

.App-body-block-content {
  width: 100%;
  height: 93%;
  display: flex;
  flex-direction: row;
}

.App-body-block-left-tab {
  width : 5%;
  background-color : #3F4757;
}

.App-body-block-left-tab-container {
  width : 100%;
  height : 100%;
  display: flex;
  flex-direction: column;
}

.App-body-block-left-tab-icon-container {
  height: 92%;
  display: flex;
  flex-direction: column;
}

.App-body-block-left-tab-icon {
  height: 5%;
  width: 75%;
  background-color: #5C6470;
  border-radius: 1px;
  box-shadow: 0 2px 0 0 #00BFA5;
  margin: 15px 10px;
  cursor: pointer;
}

.App-body-block-left-tab-icon-title {
  color: #FFFFFF;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  text-align: center;
  margin : 15px;
}

.App-body-block-right-tab {
  width : 95%;
  background-color: red;
}

.App-body-block-right-tab-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.App-body-right-tab-dashboard-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.App-body-right-tab-dashboard-title {
  height: 7%;
  display: flex;
  flex-direction: row;
  border-bottom : solid #D4D5D6 2px;
}

.App-body-right-tab-dashboard-title-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin : 1% 1%;
}

.App-body-right-tab-dashboard-title-name {
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 29px;
}

.App-body-right-tab-dashboard-refresh {
  margin: auto;
  margin-right: 10px;
  border : 1px solid black;
  height: 100%;
  width: 2%;
  background-color: #F4F5F6;
}

.App-body-right-tab-dashboard-refresh-time {
  float: right;
  font-family: "Proxima Nova";
  margin: auto ;
  margin-left: 54%;
  display: flex;
}

#App-body-right-tab-dashboard-refresh-icon{
  margin: 6px;
  cursor: pointer;
}

#App-body-right-tab-dashboard-spinner-icon{
  margin: 6px;
}

.App-body-right-tab-dashboard-title-toggle-container {
  width : 15%;
  box-sizing: border-box;
  border: 1px solid #D4D5D6;
  border-radius: 6px;
  margin : 0.5% 5%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-title-toggle-live {
  text-align: center;
  width : 50%;
  margin : 1%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-title-toggle-live-container {
  margin : auto;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-title-toggle-live-icon {
  margin-right : 10px;
}

.toggle-selected {
  border-radius: 4px;
  background-color: #4285F4;
  color : white;
}

.App-body-right-tab-dashboard-title-toggle-insights {
  text-align: center;
  width: 50%;
  margin : 1%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-title-toggle-insights-container {
  margin : auto;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-dashboard-title-toggle-insights-icon {
  margin-right : 10px;
}

.App-body-right-tab-dashboard-body {
  height: 93%;
  background-color: #F4F5F6;
  display: flex;
  flex-direction: column;
}

.yellow-bg {
  background-color: yellow;
}

.tekion-bg {
  background-color : #3F4757;
}

.App-body-block-left-tab-avatar-container {
  height: 8%; 
  border-top : solid black 1px;
}

.icon-nine-dots-menu {
  height: 24px;
  width: 24px;
  margin: 20px;
  font-size: 1.5rem;
}