.App-body-right-tab-year-progress-icon{
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	box-sizing: border-box;
	border: 1px solid #979797;
	color : #979797;
  	background-color: rgba(255,255,255,0.25);
}

.icon-completed {
	background-color: #60D156;
	color : white;
	border : 1px solid #60D156;
	font-size: 13px;
}

.icon-running {
	background-color: #0091FF;
	border : 1px solid #0091FF;
	color : white;
	font-size: 13px;
}

.icon-fail {
	background-color: red;
	border : 1px solid red;
	color : white;
}

.icon-disabled {
	background-color: white;
	border: 1px solid #979797;
	color : #979797;
}

.fa-caret-right {
	font-size: 17px;
	margin: auto;
}

.disabled-icon {
	font-size: 12px;
}

.progress-icon {
	margin: auto;
}

.progress-icon-disabled {
	margin: auto;
	font-size: 11px;
}

.icon-start {
	background-color: #0091FF;
	border : 1px solid #0091FF;
	color : white;
	font-size: 11px;
}