.App-body-right-tab-agent-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px 15px 10px 15px;
}

.App-body-right-tab-agent-content-contents {
  height: 95%;
  background-color: white;
  margin : 10px 5px;
  border-radius: 6px;
}