.year-progress-bar-envelope {
	width : 100%;
}

.year-progress-bar-container {
	display: flex;
	flex-direction: column;
	width : 25%;
	margin : auto 0px;
	background-color: #D4D5D6;
	box-sizing: border-box;
	border : 1px solid #D4D5D6;
}
