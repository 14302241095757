.App-body-right-tab-year-action-container {
	display: flex;
	flex-direction: row;
	height : 80%;
}

.App-body-right-tab-year-action-label {
	width : 100%;
	height: 100%;
	margin : auto 0px;
}

.App-body-right-tab-year-action-button {
	display: block;
	width : 200px;
	height : 30px;
	border : 1px solid #000000;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	text-align: center;
  	cursor: pointer;
}

.App-body-right-tab-year-action-button-label {
	margin: 5px;
}