.App-body-right-tab-running-jobs-modal {
	display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.App-body-right-tab-running-jobs-modal-content {
	position: relative;
  background-color: #ffffff ;
  margin-left: auto;
  margin-top: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 70%;
  height: 93%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.8s
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {right:-300px; opacity:0}
  to {right:0; opacity:1}
}

.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-right-tab-running-jobs-modal-header {
  background-color: #EDEEF0;
  height: 7%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-running-jobs-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto 25px ;
}

.App-body-right-tab-running-jobs-modal-body {
  height: 93%;
  display: flex;
  flex-direction: column;
}

.App-body-right-tab-running-jobs-modal-body-job-details {
  height: 15%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-running-jobs-modal-body-job-logs {
  height: 85%;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-running-jobs-modal-body-job-detail-group {
  width: 20%;
  display: flex;
  margin: 10px;
  flex-direction: column;
}

.App-body-right-tab-jrunning-jobs-modal-body-detail-container {
  height: 90%;
  display: flex;
  width: 90%;
  margin: 10px;
  flex-direction: column;
}

.App-body-right-tab-running-jobs-modal-body-job-detail-group-caption {
  width: 90%;
  display: flex;
  height: 20%;
  margin: auto;
  color: #444F5C;
  font-family: "Proxima Nova";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-right-tab-running-jobs-modal-body-job-detail-group-value {
  height: 20%;
  width: 90%;
  color: #161616;
  display: flex;
  margin: auto;
  font-family: "Proxima Nova Semibold";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 17px;
}