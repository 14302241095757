.progress-bar-envelope {
	width : 100%;
	background-color : #ece7e7;
	height : 5px;
	margin-top : 10px;
	margin-right : 10px;
}

.progress-bar {
	height : 5px;
  	width : 80%;
  	display : flex;
  	-ms-flex-direction: column;
  	flex-direction: column;
  	-ms-flex-pack: center;
 	justify-content: center;
  	color: #fff;
  	text-align: center;
  	white-space: nowrap;
  	background-color: green;
}

.progress-bar-container {
	display: flex;
	flex-direction: column;
}

.progress-bar-content-container {
	display: flex;
}

.bubble-handler:hover .bubble {
	display: flex;
	position: relative;
	box-shadow: 0 2px 8px 0 rgba(22,22,22,0.32);
}

.bubble-content {
	display: flex;
	flex-direction: column;
	margin : 5px 10px;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 20px;
}

.bubble 
{
	display: none;
	margin-top : -70px;
	margin-left : -60px;
	width: 250px;
	height: 70px;
	padding: 0px;
	background: #FFFFFF;
}

.bubble:after 
{
	content: "";
	position: absolute;
	bottom: -12px;
	left: 62px;
	border-style: solid;
	border-width: 12px 12px 0;
	border-color: #FFFFFF transparent;
	display: block;
	width: 0;
	z-index: 1;
}

.bubble:before 
{
	content: "";
	position: absolute;
	top: 70px;
	left: 61px;
	border-style: solid;
	border-width: 13px 13px 0;
	border-color: rgba(22,22,22,0.32) transparent;
	display: block;
	width: 0;
	z-index: 0;
}

.progress-bar-details {
	display: flex;
	flex-direction: row;
}

.progress-bar-status {
	float : left;
	width : 80%;
}

.progress-bar-percentage {
	float : right;
	width : 20%;
}