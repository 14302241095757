.App-body-right-tab-status-accordion-details-modules {
	width : 12%;
	display: flex;
	flex-direction: column;
	border : 1px solid #D4D5D6;
	border-right: none;
}

.App-body-right-tab-status-accordion-details-module {
	height: 8%;
	padding: 5px;
	border-bottom: 1px solid #D4D5D6;
	text-align: center;
	display: flex;
	flex-direction: row;
}

.App-body-right-tab-status-accordion-details-module-name {
	color: #161616;
	font-family: "Proxima Nova";
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.23px;
	line-height: 17px;
	margin: 10px 5px 0px;
	width : 50%;
}

.App-body-right-tab-status-accordion-details-module-error {
	width: 10%;
	height: 60%;
	background-color: #F52908;
	border-radius: 50%;
	display: flex;
	margin : auto;
}

.App-body-right-tab-status-accordion-details-module-error-dummy {
	width: 20%;
	height: 80%;
	display: flex;
	margin: auto;
}

.App-body-right-tab-status-accordion-details-module-error-exclamation {
	margin: auto;
}

.App-body-right-tab-status-accordion-details-module-progress {
	margin: auto;
	display: flex;
	flex-direction: column;
	width : 20%;
}

.App-body-right-tab-status-accordion-details-module-progress-value {
	color: #444F5C;
    font-family: "Proxima Nova";
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    text-align: center
}

.accordion-selected {
	border-left : 5px solid #4285F4;
	background-color: #EBF4FF;
}
