.App-body-right-tab-status-table-status-add-insights {
	margin-left : 82%;
	margin-top : -30px;
	width: 210px;
	height: 100px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	z-index: 1;
	position: relative;
	box-shadow: 0 2px 8px 0 rgba(22,22,22,0.32);
	border : 1px solid #EDEDED;	
}

.App-body-right-tab-status-table-status-add-insights-content {
	display: flex;
	height: 40%;
	cursor: pointer;
	border-bottom: 1px solid #EDEDED ;
}

.App-body-right-tab-status-table-status-add-insights-content-label {
	font-family: "Proxima Nova Semibold";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	margin : auto 10px;
  	text-align: left;
  	display: flex;
}

.close {
	float: right;
}

.App-body-right-tab-status-table-status-add-insights-content-close {
	height: 40%;
	cursor: pointer;
	border-bottom: 1px solid #EDEDED ;
}

.App-body-right-tab-status-table-status-add-insights-content-label-close {
	float : right;
	font-family: "Proxima Nova Semibold";
  	font-size: 18x;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	margin : 6px;
  	text-align: left;
}