.snippet {
    position: relative;
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto;
    box-shadow: 0 .4rem .8rem -.1rem rgba(0, 32, 128, .1), 0 0 0 1px #f0f2f7;
    border-radius: .25rem;
}

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    width: 100%;
    height: 50%;
    margin: auto;
}

.dot-elastic {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  color : pink;
  background-color: #092d5d;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -25px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #092d5d;
  color: #161616;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 25px;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #092d5d;
  color: #161616;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}