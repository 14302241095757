.App-body-right-tab-job-details-modal-body-log-container {
	display: flex;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-right-tab-job-details-modal-body-log-table-container {
	display: flex;
	flex-direction: column;
	width: 96%;
	height: 90%;
	margin: auto;
}
