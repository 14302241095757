.App-body-right-tab-running-jobs-modal-body-job-logs-table {
	width: 95%;
	height: 95%;
	margin: auto;
	display: flex;
	flex-direction: column;
}

.App-body-right-tab-running-jobs-logs-table-header {
	height: 8%;
	width: 100%;
	background-color: #F4F5F6;
	display: flex;
	flex-direction: row;
}

.App-body-right-tab-running-jobs-logs-table-body {
	height: 90%;
	width: 100%;
}

.App-body-right-tab-running-jobs-logs-table-header-name {
	border-collapse: collapse;
  	padding: 0.5rem;
  	text-align: left;
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	font-family: "Proxima Nova Semibold";
  	font-size: 15px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height: 26px;
  	top: 0;
  	position: sticky;
  	display: flex;
}

.App-body-right-tab-running-jobs-logs-table-header-content {
	margin: auto 10px;
}

#App-body-right-tab-running-jobs-logs-table-module {
	width: 17%;
}

#App-body-right-tab-running-jobs-logs-table-agent {
	width: 10%;
}

#App-body-right-tab-running-jobs-logs-table-year {
	width: 8%;
}

#App-body-right-tab-running-jobs-logs-table-last-updated {
	width: 10%;
}

#App-body-right-tab-running-jobs-logs-table-time-taken {
	width: 10%;
}

#App-body-right-tab-running-jobs-logs-table-triggered-by {
	width: 15%;
}

#App-body-right-tab-running-jobs-logs-table-status {
	width: 10%;
}

#App-body-right-tab-running-jobs-logs-table-logs {
	width: 5%;
}

#App-body-right-tab-running-jobs-logs-table-cancel {
	width: 10%;
}

#App-body-right-tab-running-jobs-logs-table-cancel-button {
	width: 10%;
}

.App-body-right-tab-running-jobs-logs-table-body-container {
	overflow: scroll;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}




