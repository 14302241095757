.App-body-status-year-job-insights-modal {
  display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.App-body-status-year-job-insights-modal-content {
  position: relative;
  background-color: #ffffff ;
  margin: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  width: 60%;
  height: 80%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  overflow : auto;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
  color: black;
  float: right;
  margin : auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.App-body-status-year-job-insights-modal-header {
  background-color: #EDEEF0;
  height: 10%;
  display: flex;
  flex-direction: row;
}

.App-body-status-year-job-insights-modal-header-content {
  float: left;
  width: 80%;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}

.App-body-status-year-job-insights-modal-body {
  height : 80%;
  flex-direction: column;
  overflow: scroll;
  border-bottom: 1px solid #D4D5D6;
}

.App-body-status-year-job-insights-modal-footer {
  background-color: white;
  height: 10%;
  display: block;
  flex-direction: row;
}

.App-body-status-year-job-insights-modal-footer-cancel {
  border : 1px solid black;
  float : right;
  color: #FFFFFF;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 2px;
  line-height: 17px;
  background-color: #4285F4;
  margin: 20px;
  margin-right: 50px;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width : 10%
}

.App-body-status-year-job-insights-modal-body-overview {
  display: flex;
  height: 20%;
}

.App-body-status-year-job-insights-modal-body-agent-status {
  height: 25%;
  display: flex;
  flex-direction: column;
}

.App-body-status-year-job-insights-modal-body-job-status {
  height: 25%;
  display: flex;
  flex-direction: column;
}


.App-body-status-year-job-insights-modal-body-overview-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin: 10px;
  margin-left: 30px ;
}

.App-body-status-year-job-insights-modal-body-overview-group-name {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10px;
}

.App-body-status-year-job-insights-modal-body-overview-dealer-id {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
}

.App-body-status-year-job-insights-modal-body-overview-dms-type {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
}

.App-body-status-year-job-insights-modal-body-overview-details-header {
  height: 40%;
  padding: 10px;
  text-align: left;
  color: #444F5C;
  font-family: "Proxima Nova";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-status-year-job-insights-modal-body-overview-details-value {
  height: 40%;
  padding: 10px;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-status-year-job-insights-modal-body-agent-status-title {
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 22px;
  margin: 10px;
  margin-left: 40px;
  height: 20%;
  display: flex;
}

.App-body-status-year-job-insights-modal-body-agent-status-table {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 95%;
  margin-left: 30px;
}

.App-body-status-year-job-insights-modal-body-agent-status-table-title {
  display: flex;
  flex-direction: row;
  height: 30%;
  margin : 10px 10px 0px 10px;
}

.App-body-status-year-job-insights-modal-body-agent-status-table-details {
  display: flex;
  flex-direction: row;
  height: 70%;
  margin : 10px 10px 0px 10px;
}

.App-body-status-year-job-insights-modal-body-agent-status-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height: 30px;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
}

.App-body-status-year-job-insights-modal-body-agent-status-detail-content {
  font-family: "Proxima Nova";
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  border-right: 1px solid #DEDFE0;
  height: 30px;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
  color: #161616;
}

.App-body-status-year-job-insights-modal-body-agent-status-title-content-detail {
  margin: auto 10px;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-count {
  width: 30%;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-live {
  width: 20%;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-failed {
  width: 20%;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-total {
  width: 21%;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-run {
  width: 15%;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-completed {
  width: 15%;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-fail {
  width: 15%;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-cancel {
  width: 15%;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-count-value {
  width: 30%;
  font-weight: 600;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-live-value {
  width: 20%;
  color: #39BF2D;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-failed-value {
  width: 20%;
  color: #F52908;
}

#App-body-status-year-job-insights-modal-body-agent-status-agent-total-value {
  width: 21%;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-run-value {
  width: 15%;
  color: #0091FF;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-completed-value {
  width: 15%;
  color: #00C400;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-fail-value {
  width: 15%;
  color: #F52908;
}

#App-body-status-year-job-insights-modal-body-agent-status-job-cancel-value {
  width: 15%;
  color: blue;
}