.App-body-right-tab-running-jobs-logs-table-body-row {
	width: 100%;
	height: 7%;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-right-tab-running-jobs-logs-table-body-row-name {
	border-collapse: collapse;
  	padding: 0.5rem;
  	text-align: left;
  	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height: 25px;
  	top: 0;
  	position: sticky;
  	display: flex;
}

.App-body-right-tab-running-jobs-logs-table-body-row-content {
	margin: auto 10px;
}

#App-body-right-tab-job-details-modal-body-detail-user-profile {
	display: flex;
	flex-direction: row;
}

#App-body-right-tab-job-details-modal-body-detail-user-profile-image {
	height: 80%;
	margin: 0 5px;
}

.App-body-right-tab-running-jobs-logs-table-body-row-cancel-job {
	box-sizing: border-box;
	border: 1px solid #969AA3;
  	border-radius: 2px;
  	height: 100%;
  	width: 100%;
  	display: flex;
  	cursor: pointer;
}

.App-body-right-tab-running-jobs-logs-table-body-row-cancel-job-label {
	color: #F52908;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.25px;
  	line-height: 17px;
  	margin-left: 10px;
  	margin-top : 2px;
}