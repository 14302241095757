.App-login{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.App-login-left-tab {
	width: 70%;
	display: flex;
	background: radial-gradient(circle, rgba(120,150,203,0.94) 0%, #5168B4 100%);
	height: 100%;
}

.App-login-right-tab {
	height: 100%;
	width: 30%;
	display: flex;
	flex-direction: column;
}

.App-login-right-tab-logo {
	margin: auto;
	height: 20%;
	justify-items: center;
	display: flex;
}

.App-login-right-tab-form {
	height: 80%;
	display: flex;
}

.App-login-right-tab-form-container {
	display: flex;
	margin: auto;
	flex-direction: column;
	width: 90%;
	height: 70%;
}

.App-login-right-tab-form-email {
	display: flex;
	flex-direction: column;
	height: 20%;
}

.App-login-right-tab-form-email-label {
	display: flex;
	margin: 10px 10px;
	color: #444F5C;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
}

.App-login-right-tab-form-email-box {
	width: 80%;
	height: 30%;
	margin: 0 10px;
}

.App-login-right-tab-form-password {
	display: flex;
	flex-direction: column;
	height: 20%;
}

.App-login-right-tab-form-password-label {
	display: flex;
	margin: 10px 10px;
	color: #444F5C;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
}

.App-login-right-tab-form-password-box {
	width: 80%;
	height: 30%;
	margin: 0 10px;
}

.App-login-right-tab-form-submit {
	display: flex;
	width: 90%;
	height: 20%;
	margin : 0 10px;
}

.App-login-right-tab-form-submit-button {
	background-color: #4285F4;
	width: 91px;
	height: 32px;
	color: #FFFFFF;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	text-align: center;
  	display : flex;
  	flex-direction: row;
  	margin: auto 0px;
  	justify-content: center;
  	cursor: pointer;
}

.App-login-right-tab-form-submit-button-label {
	margin: 8px;
}

#App-login-logo{
}

.App-login-left-tab-container {
	display: flex;
	margin: auto;
}