.App-body-right-tab-dashboard-content-table-container {
  height : 95%;
  margin : 10px;
  overflow: scroll;
}

.App-body-right-tab-dashboard-content-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

#App-body-right-tab-dashboard-content-table-data-dealer-id {
  text-align: right;
  padding-right : 20px ;
}

.App-body-right-tab-dashboard-content-table td {
  padding: 0.5rem;
  border-collapse: collapse;
  text-align: left;
  color: #161616;
  font-family: "Proxima Nova";
  font-size: 15px;
  letter-spacing: 0.3px;
  line-height: 17px;
}

.App-body-right-tab-dashboard-content-table tr {
  border-bottom: 1px solid #EDEDED;
}

.App-body-right-tab-dashboard-content-table th {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height : 35px;
}

#App-body-right-tab-dashboard-content-table-header {
  position: sticky;
  top : 0;
}

#App-body-right-tab-dashboard-content-table-data-tenant-name {
  width : 15%;
}

#App-body-right-tab-dashboard-content-table-data-dealer-name {
  width : 25%
}

#App-body-right-tab-dashboard-content-table-data-dealer-id-header {
  width : 5%;
  text-align: center;
}

#App-body-right-tab-dashboard-content-table-data-dms-type {
  width : 10%;
}

#App-body-right-tab-dashboard-content-table-data-go-live {
  width : 10%;
}

#App-body-right-tab-dashboard-content-table-data-progress-bar {

}