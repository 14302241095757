.App-body-right-tab-job-details-modal-body-validation-result-header {
	height: 20%;
	display: flex;
}

.App-body-right-tab-job-details-modal-body-validation-result-header-content {
	margin: 5px 25px;
	color: #161616;
	font-family: "Proxima Nova";
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 22px
}

.App-body-right-tab-job-details-modal-body-validation-result-content {
	height: 80%;
	display: flex;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 90%;
	margin: auto;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-header {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #F4F5F6;
	height: 35%;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-title-content {
	border-collapse: collapse;
  	padding: 0.5rem;
  	text-align: left;
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	font-family: "Proxima Nova Semibold";
  	font-size: 15px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height: 25px;
  	top: 0;
  	position: sticky;
  	display: flex;
  	flex-direction: row;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-title-content-detail {
	margin: auto 10px;
}



.App-body-right-tab-job-details-modal-body-validation-result-table-body {
	display: flex;
	flex-direction: column;
	height: 60%;
	width: 100%;
	overflow: scroll;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-body-content {
	height: 100%;
  	display: flex;
  	flex-direction: row;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-content {
	padding: 0.5rem;
  	border-collapse: collapse;
  	text-align: left;
  	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height : 30px;
  	display: flex;
  	border-bottom : 1px solid #D4D5D6;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-content-detail {
	margin: auto 10px;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-body {
	display: flex;
	flex-direction: column;
	height: 75%;
	width: 100%;
	overflow: scroll;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-body-content {
	height: 100%;
  	margin: 0px 10px;
  	display: flex;
  	flex-direction: row;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-content {
	padding: 0.5rem;
  	border-collapse: collapse;
  	text-align: left;
  	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height : 25px;
  	display: flex;
  	border-bottom : 1px solid #D4D5D6;
  	margin: 0px;
}

.App-body-right-tab-job-details-modal-body-validation-result-table-content-detail {
	margin: auto 10px;
}

.tekion-success-color{
  color:#52a255;
  cursor: pointer;
}

.tekion-fail-color{
  color: #de6464;
  cursor: pointer;
}

#App-body-right-tab-job-details-modal-title-validation-year {
	width: 30%;
}

#App-body-right-tab-job-details-modal-title-validation-size {
	width: 30%;
}

#App-body-right-tab-job-details-modal-title-validation-count {
	width: 30%;
}

#App-body-right-tab-job-details-modal-content-validation-year {
	width: 30%;
}

#App-body-right-tab-job-details-modal-content-validation-size {
	width: 30%;
}

#App-body-right-tab-job-details-modal-content-validation-count {
	width: 30%;
}