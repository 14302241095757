.App-body-right-tab-year-progress-body-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
}

.App-body-right-tab-year-progress-body-title {
  display: flex;
  flex-direction: row;
  height: 12%;
  margin : 10px 10px 0px 10px;
}

.App-body-right-tab-year-progress-body-title-content {
  border-collapse: collapse;
  padding: 0.5rem;
  text-align: left;
  background-color: #F4F4F4;
  border-right: 1px solid #DEDFE0;
  color: #161616;
  font-family: "Proxima Nova Semibold";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 17px;
  height: 35px;
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: row;
}

.App-body-right-tab-year-progress-body-title-content-detail {
  margin: auto 10px;
}

#App-body-right-tab-year-progress-body-title-year{
  width: 10%;
}

#App-body-right-tab-year-progress-body-title-extract-description{
  width: 70%;
  display: flex;
  flex-direction: row;
}

#App-body-right-tab-year-progress-body-title-actions{
  width: 20%;
}

#App-body-right-tab-year-progress-body-title-detail-arrow{
  margin : auto;
  font-size: 24px;
  color: #bfbfbc;
}

