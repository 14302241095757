.App-body-right-tab-agent-content-status {
	display : flex;
	flex-direction : row;
}

.App-body-right-tab-agent-content-status-icon-online {
	height: 12px;
  	width: 12px;
  	border-radius: 12px;
  	background-color: #60D156;
  	margin-top : 3px;
}

.App-body-right-tab-agent-content-status-icon-offline {
	height: 12px;
  	width: 12px;
  	border-radius: 12px;
  	background-color: #F52908;
  	margin-top : 3px;
}

.App-body-right-tab-agent-content-status-overview {
	margin-left: 10px;
}