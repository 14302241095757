.App-body-right-tab-job-details-modal-body-log-table-body {
	display: flex;
	flex-direction: column;
	height: 75%;
	width: 100%;
	overflow: scroll;
}

.App-body-right-tab-job-details-modal-body-log-table-body-content {
	height: 100%;
  	margin: 0px 10px;
  	display: flex;
  	flex-direction: row;
}

.App-body-right-tab-job-details-modal-body-log-table-content {
	padding: 0.5rem;
  	border-collapse: collapse;
  	text-align: left;
  	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 15px;
  	letter-spacing: 0.3px;
  	line-height: 17px;
  	height : 30px;
  	display: flex;
  	border-bottom : 1px solid #D4D5D6;
}

.App-body-right-tab-job-details-modal-body-log-table-content-detail {
	margin: auto 10px;
}

#App-body-right-tab-job-details-modal-content-audit-id {
	width: 30%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-content-status {
	width: 15%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-content-message {
	width: 35%;
	margin: auto;
}

#App-body-right-tab-job-details-modal-content-triggered-time {
	width: 20%;
	margin: auto;
}